.toast-container {
  &.Toastify {
    &__toast-container {
      padding: 0;

      &--top-right {
        top: 12px;
        right: 12px;
      }

      &--top-left {
        top: 12px;
        left: 12px;
      }

      &--top-center {
        top: 12px;
      }

      &--bottom-right,
      &--bottom-left,
      &--bottom-center {
        .Toastify__toast {
          margin-top: 12px;
          margin-bottom: 0;
        }
      }

      &--bottom-right {
        bottom: 12px;
        right: 12px;
      }

      &--bottom-left {
        bottom: 12px;
        left: 12px;
      }

      &--bottom-center {
        bottom: 12px;
      }
    }
  }

  .Toastify {
    &__toast {
      border-radius: 6px;
      margin-bottom: 12px;
      padding: 8px 8px 8px 16px;

      &--success,
      &--warning,
      &--error,
      &--info {
        .Toastify__toast-body {
          color: var(--white);
        }
      }

      &--success {
        background: var(--green);
      }

      &--warning {
        background: var(--orange);
      }

      &--error {
        background: var(--red);
      }

      &--info {
        background: var(--blue);
      }
    }

    &__toast-body {
      color: var(--dk_gray);
      font-family: var(--font-stack-med);
      font-size: 14px;
      line-height: 1.6;
    }

    &__progress-bar {
      height: 4px;

      &--default {
        background: transparent;
        background-color: var(--blue);
      }
    }
  }
}
