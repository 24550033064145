@import "./mixins.scss";

.header {
  display: flex;
  padding: 48px;

  .logo {
    width: 48px;
    max-width: 48px;
    margin-right: 64px;

    @media only screen and (max-width: 992px) {
      margin-right: 32px;
    }

    @media only screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }
}

.menu-nav {
  display: flex;

  li {
    &:not(:last-child) {
      margin-right: 48px;

      @media only screen and (max-width: 992px) {
        margin-right: 24px;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    color: var(--off_black);
    font-family: var(--font-stack-med);
    font-size: 14px;
    text-decoration: none;
    letter-spacing: 4px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    svg {
      margin-left: 4px;
    }
  }
}

.standards-list {
  width: 144px;
  min-width: 144px;
  margin-top: 4px;
  margin-right: 96px;

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  .btn {
    justify-content: space-between;
    letter-spacing: 4px;
    padding: 12px 16px;
    transition: var(--default-transition);

    &:hover,
    &:focus {
      width: 112%;
    }

    > div {
      text-align: left;
    }

    > div,
    img {
      pointer-events: none;
    }

    p {
      letter-spacing: 2px;
      margin-top: 2px;
      opacity: 0.8;
    }

    img {
      margin-right: -8px;
    }
  }
}

.hero-img {
  width: 100%;
}

.footer {
  display: flex;
  padding: 48px 48px 24px 48px;
  margin-top: auto;

  li {
    display: flex;
    align-items: center;
    height: 20px;
    color: var(--md_gray);
    font-family: var(--font-stack-med);
    font-size: 12px;
    padding: 0 16px;
    border-right: 1px var(--md_gray) solid;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  .copyright {
    position: relative;
    font-family: Arial;
    font-size: 14;
    font-weight: bold;
    top: 1;
    margin-right: 4px;
  }

  a {
    color: var(--brand);
    font-size: 12px;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .ifac-logo {
    margin-top: 2px;
    margin-left: 8px;
    opacity: 0.65;
    filter: grayscale(1);
    transition: var(--default-transition);

    &:hover,
    &:focus {
      opacity: 1;
      filter: grayscale(0);
    }

    img {
      height: 24px;
    }
  }
}

@include mq("phone-wide") {
  .standards-list {
    width: 100% !important;
    margin: 0;
  }
}

@include mq("tablet") {
  .header,
  .footer {
    padding: 24px;
  }

  .header {
    .menu-nav {
      display: none;
    }
  }

  .footer-inner {
    display: block;

    ul {
      display: block;

      li {
        padding: 12px 0;
        border-right: none;
      }
    }
  }
}

@include mq("tablet-wide") {
  .standards-list {
    width: 200px;
    min-width: 200px;
    margin: 0;
  }
}
