@import "./mixins.scss";

//
// GRID
//

.grid-columns {
  @include grid-columns;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 24px;
  margin: 0 auto;

  &--fluid {
    max-width: 100%;
    margin: 0;
  }
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.hide-xs {
  display: none;
}

.show-xs {
  display: flex;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.row-xs {
  flex-direction: row;
}

.column-xs {
  flex-direction: column;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@include mq("phone-wide") {
  .hide-xxs {
    display: none;
  }

  .show-xxs {
    display: flex;
  }

  .col-xxs,
  .col-xxs-1,
  .col-xxs-2,
  .col-xxs-3,
  .col-xxs-4,
  .col-xxs-5,
  .col-xxs-6,
  .col-xxs-7,
  .col-xxs-8,
  .col-xxs-9,
  .col-xxs-10,
  .col-xxs-11,
  .col-xxs-12,
  .col-xxs-offset-0,
  .col-xxs-offset-1,
  .col-xxs-offset-2,
  .col-xxs-offset-3,
  .col-xxs-offset-4,
  .col-xxs-offset-5,
  .col-xxs-offset-6,
  .col-xxs-offset-7,
  .col-xxs-offset-8,
  .col-xxs-offset-9,
  .col-xxs-offset-10,
  .col-xxs-offset-11,
  .col-xxs-offset-12 {
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .col-xxs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xxs-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xxs-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xxs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xxs-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xxs-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xxs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xxs-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xxs-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xxs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xxs-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xxs-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xxs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xxs-offset-0 {
    margin-left: 0;
  }

  .col-xxs-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xxs-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xxs-offset-3 {
    margin-left: 25%;
  }

  .col-xxs-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xxs-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xxs-offset-6 {
    margin-left: 50%;
  }

  .col-xxs-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xxs-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xxs-offset-9 {
    margin-left: 75%;
  }

  .col-xxs-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xxs-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-xxs {
    justify-content: flex-start;
    text-align: start;
  }

  .center-xxs {
    justify-content: center;
    text-align: center;
  }

  .end-xxs {
    justify-content: flex-end;
    text-align: end;
  }

  .top-xxs {
    align-items: flex-start;
  }

  .middle-xxs {
    align-items: center;
  }

  .bottom-xxs {
    align-items: flex-end;
  }

  .around-xxs {
    justify-content: space-around;
  }

  .between-xxs {
    justify-content: space-between;
  }

  .row-xxs {
    flex-direction: row;
  }

  .column-xxs {
    flex-direction: column;
  }

  .first-xxs {
    order: -1;
  }

  .last-xxs {
    order: 1;
  }
}

@include mq("tablet", $type: min) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: flex;
  }

  .container {
    max-width: 768px;
    padding: 0 48px;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .row-sm {
    flex-direction: row;
  }

  .column-sm {
    flex-direction: column;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@include mq("tablet-wide", $type: min) {
  .hide-md {
    display: none;
  }

  .show-md {
    display: flex;
  }

  .container {
    max-width: 992px;
    padding: 0 48px;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .row-md {
    flex-direction: row;
  }

  .column-md {
    flex-direction: column;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@include mq("desktop", $type: min) {
  .hide-lg {
    display: none;
  }

  .show-lg {
    display: flex;
  }

  .container {
    max-width: 1170px;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    flex: 0 0 auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .row-lg {
    flex-direction: row;
  }

  .column-lg {
    flex-direction: column;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}
