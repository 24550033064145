//
// Popovers
//

.popover {
  position: relative;
  display: inline-block;

  .popover-content {
    display: block !important;
    position: absolute;
    width: 320px;
    // width: 100%;
    // min-width: 128px;
    bottom: 26px;
    font-size: 13px;
    font-style: normal;
    font-family: var(--font-stack-med);
    color: var(--white);
    background-color: var(--off_black);
    border-radius: 6px;
    padding: 8px 12px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) translateY(12px);
    transition: opacity 0.2s cubic-bezier(0.33, 0.66, 0.66, 1), visibility 0.2s cubic-bezier(0.33, 0.66, 0.66, 1),
      transform 0.2s cubic-bezier(0.33, 0.66, 0.66, 1);
    pointer-events: none;

    &.shown {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) translateY(0);
      pointer-events: all;

      &:after {
        transform: translateX(-50%) translateY(0);
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -6px;
      border-top: 6px solid var(--off_black);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transform: translateX(-50%) translateY(-8px);
      transition: var(--default-transition);
      transition-delay: 0.15s;
    }
  }

  .popover-hover {
    color: initial;
    background-color: var(--lt_gray);
    transition: var(--default-transition);
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--brand);
      background-color: transparent;
    }
  }
}
